<template>
  <div>
    <div class="table-responsive">
      <table class="table table-borderless table-hover text-nowrap">
        <thead>
          <tr>
            <th scope="col">No</th>
            <th scope="col">Name</th>
            <th scope="col">Score</th>
            <th scope="col">Date</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(data, i) of report" :key="data.id">
            <td>{{ getNumber(i) }}</td>
            <td>{{ data.email }}</td>
            <td>{{ data.score }}</td>
            <td>
              {{ moment(data.createdAt).format('DD-MM-YYYY HH:mm:ss') }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <TableLoading v-if="!report.length && isFetching" />
    <TableNoData v-else-if="!report.length" />

    <Pagination
      v-if="meta && meta.lastPage > 1"
      class="mt-4 mt-md-5"
      :current="meta.currentPage"
      :last="meta.lastPage"
    />
  </div>
</template>

<script>
import hasBootstrapTooltip from '../../mixins/hasBootstrapTooltip';
import tableUtil from '../../mixins/tableUtil';
import Report from '../../utils/models/Report';

import Pagination from '../pagination/Pagination';
import TableLoading from '../table/TableLoading.vue';
import TableNoData from '../table/TableNoData.vue';
import moment from 'moment';

export default {
  mixins: [hasBootstrapTooltip, tableUtil],

  components: {
    Pagination,
    TableLoading,
    TableNoData,
  },

  data() {
    return {
      isFetching: false,
      report: [],
      meta: null,
    };
  },

  methods: {
    async fetch() {
      try {
        this.isFetching = true;
        ({ data: this.report, meta: this.meta } = await Report.fetch(
          this.$route.query
        ));
      } finally {
        this.isFetching = false;
      }
    },
  },

  watch: {
    '$route.query': {
      handler() {
        this.fetch();
      },
      deep: true,
    },
  },

  created() {
    this.Report = Report;
    this.fetch();
  },
  beforeCreate() {
    this.moment = moment;
  },
};
</script>

<style lang="scss" scoped>
.actions {
  .spinner-border {
    width: 1em;
    height: 1em;
  }
}
</style>
