<template>
  <div>
    <div class="d-flex flex-wrap mb-4 mb-md-5">
      <div class="me-auto">
        <input
          v-model="input._search"
          type="search"
          class="form-control mb-1"
          placeholder="Search by Name . . ."
        />
      </div>
      <div>
        <button class="btn btn-primary mr-3" @click="handleExportClick">
          Download Excel
          <span
            v-if="isExporting"
            class="spinner-border spinner-border-sm ms-1"
          ></span>
        </button>
      </div>
    </div>

    <ReportTable />
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import pickBy from 'lodash/pickBy';

import report from '../../api/reports';
import ReportTable from '../../components/reports/ReportTable.vue';

export default {
  components: { ReportTable },

  data() {
    return { input: { ...this.$route.query }, isExporting: false };
  },

  methods: {
    async handleExportClick() {
      try {
        this.isExporting = true;
        const response = await report.fetchExportUrl({
          ...this.$route.query,
        });
        location.href = response.data.data.url;
      } catch (error) {
        this.$alert.requestError(error);
      } finally {
        this.isExporting = false;
      }
    },
    push() {
      let query = { ...this.$route.query, ...this.input };
      query = pickBy(query, (value) => value !== '');

      this.$router.replace({ query });
    },
  },

  watch: {
    input: {
      handler() {
        this.pushDebounced();
      },
      deep: true,
    },
  },

  created() {
    this.pushDebounced = debounce(this.push, 400);
  },
};
</script>
